import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-puppy-prev',
  templateUrl: './puppy-prev.component.html',
  styleUrls: ['./puppy-prev.component.css']
})
export class PuppyPrevComponent implements OnInit {

  data = [
    'SpeciesType',
    'WorkArea',
    'TreatMent'
  ];
  // data holders
  selectedGuidelineID: number;
  selectedPuppyData: any;
  versiondata: any;
  Version: any;
  SpeciesType: any;
  WorkArea: any;
  TreatMent: any
  treatmentNames: any;

  // input options
  @ViewChild('txtNameOfGuideline') txtNameOfGuideline: ElementRef;
  treatmentNotes: any;
  treatmentDays: any;
  treatmentBirth: any;

  // select options  
  @ViewChild('SpeciesOption') SpeciesOption: ElementRef;
  @ViewChild('WAOption') WAOption: ElementRef;
  @ViewChild('treatMentName') treatMentName: ElementRef;

  subUrl: string = AppUrls._animalInfoModule;
  constructor(private _http: HttpService, private _activeRoute: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this._activeRoute.params.subscribe(resp => {
      this.selectedGuidelineID = resp['id'];
    });
    //this.getSelectedPuppyData();
    //this.getTreatmentsTable();
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    console.log(this.data,"getcatdata");
    this.getversionlist();
    this.getSelectedPuppyData();
    this.getTreatmentsTable();
  }

  getSelectedPuppyData() {
    let params = {
      action: 'guideline',
      id: this.selectedGuidelineID
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        this.selectedPuppyData = resp[0];
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'SpeciesType') {
          this.SpeciesType = resp;
        }
        else if (type == 'WorkArea') {
          this.WorkArea = resp;
        }
        else if (type == 'TreatMent') {
          this.TreatMent = resp;
        }
        else if(type == 'Version') {
          this.versiondata = resp;
        }
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getversionlist(){
    let params={
      action:'guideline',
      name:'listOfVersionCodes',
      id:this.selectedGuidelineID,
    }
  this._http.post(params, this.subUrl).subscribe(resp => {
    if (resp.length) {
      this.versiondata = resp[0];
    }
    else {
      this.openSnackBar(resp['msg'], '5000', 'close');
    }
  });
}

  // deleteTreatment(treat) {
  //   let params = {
  //     action: 'deleteTreatmentGuidelinesChild',
  //     id: treat.id
  //   }
  //   this._http.post(params, this.subUrl).subscribe(resp => {
  //     if (resp['status'] = 'success') {
  //       this.getTreatmentsTable();
  //       this.openSnackBar(resp['msg'], '5000', 'close');
  //     }
  //     else {
  //       this.openSnackBar(resp['msg'], '5000', 'close');
  //     }
  //   });
  // }

  addTreatment(value1: string, value2: string, value3: string) {
    let params = {
      action: 'updateTreatment',
      id: this.selectedPuppyData.name,
      notes: value1,
      startAfterBirthOrDeley: value2,
      days: value3,

    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getTreatmentsTable();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  updateTreatMent() {
    let params = {
      action: 'updateGuideline',
      id: this.selectedPuppyData.id,
      name: this.selectedPuppyData.name,
      versionCode: this.selectedPuppyData.versionCode,
      speciesTypeID: this.selectedPuppyData.speciesTypeID,
      workAreaID: this.selectedPuppyData.workAreaID,
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getTreatmentsTable();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  getTreatmentsTable() {
    let params = {
      action: 'treatmentList',
      guidelineId: this.selectedGuidelineID
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.treatmentNames = resp;
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getGuidelineVersion(guideline: any): string {
    if (guideline.version) {
      return guideline.version;
    }
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
