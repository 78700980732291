import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-puppy-prev-list',
  templateUrl: './puppy-prev-list.component.html',
  styleUrls: ['./puppy-prev-list.component.css']
})
export class PuppyPrevListComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  puppiesPreventData: any;
  dataTable: any;
  guidelineListData:any;
  selectedPuppyTable: any;
  id:any;
  versionsdata: any;
  //gid: any;

  constructor(private _http: HttpService, private chRef: ChangeDetectorRef, private _router: Router, public snackBar: MatSnackBar) { }

  async ngOnInit() {
    await this.getPuppiesPreventList();
    //await this.openUpdateVersionModal;
    //this.guidelineList();
  }

  async getPuppiesPreventList() {
    let params = {
      action: 'guidelineList'
    }
    await this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.puppiesPreventData = resp;
        setTimeout(() => {$('#puppyListTable').DataTable()},500);
       // this.chRef.detectChanges();
        //const table: any = $('#puppyListTable');
        //this.dataTable = table.DataTable();
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  async openUpdateVersionModal(guideline) {
    $('#updateVersionModal').modal('show');
    let params={
      action:'guideline',
      name:'listOfVersionCodes',
      id:guideline.id,
    }
  await this._http.post(params, this.subUrl).subscribe(resp => {
    if (resp.length) {
      this.versionsdata = resp[0];
    }
    else {
      this.openSnackBar(resp['msg'], '5000', 'close');
    }
  });
  }

  onSave(): void {
    // Handle the Save button logic here
    //$('#updateVersionModal').modal('hide');
  }

  guidelineList(){
    let params = {
      action: 'guidelineList'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.guidelineList = resp;
        this.chRef.detectChanges();
        const table: any = $('#guidelineListTable');
        this.selectedPuppyTable= table.selectedPuppyTable();
      }
      // else {
      //   this.openSnackBar('OOPS! Something Went Wrong!', '5000', 'close');
      // }
    });
  }
  //MEthod for getting latest version
  getverisoncode(versionCode: string) {
    let params = {
      action: 'guidelineList',
      versionCode: versionCode
    };
    this._http.post(params, this.subUrl).subscribe((resp: any) => {
      if (resp) {
        this.puppiesPreventData = resp;
        this.chRef.detectChanges();
        const table: any = $('#puppyListTable');
        this.dataTable = table.DataTable();
      }
    });
  }

  //Methof to handle file uploads
  uploadFile(event: any,id:number) {
    const file = event.target.files[0];
    if (file) {
      this.readFile(file,id);
    }
  }

  readFile(file: File,id) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
      console.log('JSON Data:', jsonData);
      this.addDataToTreatmentsList(jsonData,id);
    };

    reader.readAsArrayBuffer(file);
  }
  addDataToTreatmentsList(data: any[],id) {
    data.splice(0,1);
    const treatmentsList = data.filter(row => row[1] && row[3] && row[4]).map(t => {
      return{
        name:t[1],
        notes:t[2],
        startAfterBirthOrDeley:t[3],
        days:t[4]
      }
    });
    console.log(treatmentsList);
    let params={
      action: 'addTreatment',
      guidelineId: id,
      treatmentList: JSON.stringify(treatmentsList)
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      try {
        if (resp['status'] === 'success') {
          this.openSnackBar(resp['msg'], '5000', 'close');
        } else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      } catch (error) {
        console.error('Error uploading file response:', error);
      }
    });
  }


  viewPuppyPreventative(guideline) {
    //this.gid=guideline.id;
   // console.log(this.gid);
    this._router.navigate([`/puppy-preventative/${guideline.id}`]);
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}