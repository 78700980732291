export const AppUrls = {
  _hashKey: "U2FsdGVkX18ZUVvShFSES21qHsQEqZXMxQ9zgHy+bu0=",
   _baseUrl: "http://pbsapi.palamur.com/",
  //_baseUrl: "https://www.palamur.com/api/",
  // _baseUrl: "http://uatapi.palamur.com/",
  // _baseUrl: "http://localhost/palamur/",
  // "http://192.168.0.183/pbs_animal/",
    _animalInfoModule:
    "modules/animalInfoModule/operations/animal_info_operations.php",
};
